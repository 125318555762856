/**
 * This state holds the pixels required to offset the main content when the mobile menu is open
 * because of the notice banners above the header.
 *
 * Example value: 'padding-top: 100px'
 * It is automatically unset when necessary, so it can be directly bound to the `style` attribute.
 */
export function useStateMainContentTopPadding() {
    return useState<string | undefined>(() => undefined)
}
